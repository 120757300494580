<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF BANK DEPOSITORIES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Bank Code
            </th>
            <th class="text-center text-uppercase">
              Account #
            </th>
            <th class="text-center text-uppercase">
              Bank Name
            </th>
            <th class="text-center text-uppercase">
              Bank Address
            </th>
            <th class="text-center text-uppercase">
              Status
            </th>
            <th class="text-center text-uppercase" v-if="position==='ADMIN'">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in bank_items"
            :key="item.id"
          >
            <td>{{ item.bank_code_name }}</td>
            <td class="text-center">
              {{ item.account_no }}
            </td>
            <td class="text-center">
              {{ item.bank_name }}
            </td>
            <td class="text-center">
              {{ item.bank_address }}
            </td>
            <td class="text-center">
              <div v-if="item.active===1">
                <v-chip color="error" dark>DEACTIVATED</v-chip>
              </div>
              <div v-else-if="item.active===0">
                <v-chip color="success" dark>ACTIVE</v-chip>
              </div>
            </td>
            <td class="text-center" v-if="position==='ADMIN'">
              <div v-if="item.active===1">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="actions(item,0)"
                >
                  {{icons.mdiToggleSwitchOff}}
                </v-icon>
              </div>
              <div v-else-if="item.active===0">
                <v-icon
                  class="mr-2"
                  color="success"
                  @click="actions(item,1)"
                >
                  {{icons.mdiToggleSwitch}}
                </v-icon>
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiToggleSwitchOff, mdiToggleSwitch} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      this.list_of_bank()
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiToggleSwitchOff,
          mdiToggleSwitch
        },
      }
    },
    data() {
      return {
        bank_items: [],
      }
    },
    computed: {
      ...mapGetters('authentication', ['position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_depositories', ['get_list_of_bank_depository', 'change_status']),
      actions(item, status) {
        const data = new FormData()
        data.append('id', item.id);
        data.append('status', status);
        data.append('bank_code_name', item.bank_code_name);
        this.change_status(data)
          .then(response => {
            if (status === 1) {
              this.change_snackbar({
                show: true,
                color: 'error',
                text: response.data,
              })
            } else {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
            }
            this.list_of_bank()
          })
          .catch(error => {
            console.log(error)
          })
      },
      list_of_bank() {
        this.get_list_of_bank_depository()
          .then(response => {
            this.bank_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  }
</script>
